<template>
    <article>
        <hero-image
            :alt="hero.alt"
            :heading="hero.heading"
            :img-small="hero.imgSmall"
            :img-large="hero.imgLarge"
        />
        <v-container class="py-0">
            <v-row no-gutters align="center">
                <v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
                    <breadcrumbs :crumbs="crumbs" />
                </v-col>
                <v-col cols="12" sm="5">
                    <social :meta="meta" />
                </v-col>
            </v-row>
        </v-container>
        <v-container class="content" tag="section">
            <v-row>
                <v-col
                    cols="12"
                    sm="4"
                    md="3"
                    class="text-center"
                >
                    <v-img
                        alt="Alicia de Vries"
                        aspect-ratio="1"
                        height="200"
                        src="/img/alicia.jpg"
                        width="200"
                        class="image-profile"
                    />
                    <h2 class="image-profile__title">
                        Alicia de Vries
                    </h2>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                    <p>
                        Alicia de Vries is a Clinical Neuropsychologist with over 10 years experience working with children of all ages with a wide range of cognitive and behavioural concerns. Her particular interests include childhood developmental disorders (such as ADHD, Autism, learning difficulties), children in out of home care and epilepsy.
                    </p>
                    <p>
                        Alicia has gained vast experience working across multiple fields in the health care sector. Her current appointment is within the Neurology and Neurosurgery department at the Children's Hospital at Westmead working with children with complex epilepsy. Previous roles at Westmead included the Child Development Unit (diagnostic and assessment team for <router-link to="/services/autism-spectrum-disorder">
                            Autism Spectrum Disorder
                        </router-link>) and Oncology Department (working with children treated for childhood cancer).  During this appointment she was involved in research looking at cognitive quality of life for children who have undergone bone marrow transplant.
                    </p>
                    <p>
                        Alicia is warm and empathetic professional with exceptional interpersonal skills who prides herself in working passionately with families to ensure their children reach their best potential. Her assessments are designed to target referral questions and the concerns of parents within a comprehensive assessment and developmental framework.
                    </p>
                    <p>
                        Alicia is registered as a Clinical Neuropsychologist with the Australian Board of Psychology. She holds a Masters degree in Clinical Neuropsychology from Macquarie University and a Bachelor of Psychology with Honours from the University of New England. Her masters thesis focused on the day to day executive dysfunction and neuropsychological outcomes of maltreated childhood in Out of Home Care.
                    </p>
                    <router-link to="/contact">
                        Contact Alicia de Vries
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col
                    cols="12"
                    sm="4"
                    md="3"
                    class="text-center"
                >
                    <v-img
                        alt="Stephanie O'Hare"
                        aspect-ratio="1"
                        height="200"
                        src="/img/stephanie.jpg"
                        width="200"
                        class="image-profile"
                    />
                    <h2 class="image-profile__title">
                        Stephanie O'Hare
                    </h2>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                    <p>
                        Stephanie O'Hare is a Clinical Neuropsychologist. She has completed her Masters in Clinical Neuropsychology and since this time has worked with children and adolescents with a range of developmental and acquired conditions. Stephanie works in the public health care system, with a permanent appointment at the Perinatal, Child and Youth Mental Health Service in the Western Sydney Local Area Health Service. Within this service she provides neuropsychological assessment for children and adolescents with a range of mental health conditions, behavioural concerns, learning difficulties, intellectual disabilities and other developmental disorders (e.g. ADHD). Stephanie also provides cognitive remediation and recommendations to support patients with their cognitive difficulties.
                    </p>
                    <p>
                        Previously, Stephanie worked in the Queensland Paediatric Rehabilitation Service at the Lady Cilento Children's Hospital and for the
                        Queensland Cerebral Palsy and Rehabilitation Research Centre in Brisbane. Within these services she worked with children with acquired
                        brain injury and cerebral palsy. She provided
                        <router-link to="/services">
                            neuropsychological and clinical services
                        </router-link>&nbsp;for children, adolescents and their families.
                        During her work for the Queensland Cerebral Palsy and Rehabilitation Research Centre she worked on an intervention study which targeted
                        cognitive and physical rehabilitation for children and adolescents who had an acquired brain injury or cerebral palsy. She has presented
                        this research at multiple international and national conferences, as well as, published research papers.
                    </p>
                    <p>
                        Stephanie is registered as a Clinical Neuropsychologist with the Australian Board of Psychology. She holds a Masters degree in Clinical
                        Neuropsychology from Macquarie University and a Bachelor of Science, Psychology with Honours from Macquarie University. Her masters
                        thesis focused on the attention outcomes of maltreated childhood in Out of Home Care. Stephanie's assessments aim to understand the
                        cognitive strengths and difficulties of the child in the context of the referral question.
                    </p>
                    <router-link to="/contact">
                        Contact Stephanie O'Hare
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
    name: 'About',
    components: {
        Breadcrumbs,
        HeroImage,
        Social
    },
    mixins: [_MxAppViewClass, _MxMeta],
    data() {
        return {
            crumbs: [
                {
                    text: 'home',
                    disabled: false,
                    to: '/'
                },
                {
                    text: this.$route.name,
                    disabled: true
                }
            ],
            hero: {
                alt: this.$route.name,
                heading: this.$route.name,
                imgSmall: 'hero-05-small',
                imgLarge: 'hero-05-large'
            },
            meta: {
                description: 'Meet the experienced team at Sydney Kids Neuropsychology.',
                title: 'About Sydney Kids Neuropsychology'
            }
        };
    }
};
</script>

<style scoped lang="scss">
.image-profile {
    border-radius: 50%;
    margin: 0 auto;
    &__title {
        margin-top: gutter-size(4) !important;
        text-align: center;
        &:after {
            display: none !important;
        }
    }
}
</style>
